.best-sellers {
  text-align: center;
}

.title {
  color: #dc3545;
  font-size: 2rem;
  margin-bottom: 20px;
}

.products-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.product-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 250px;
  text-align: center;
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-card:hover {
  transform: scale(1.05);
}

.product-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.product-name {
  margin-top: 15px;
  font-size: 1.2rem;
  color: #333;
}

.product-description {
  margin: 10px 0;
  color: #555;
}

.product-price {
  margin-bottom: 10px;
  color: #28a745;
}

.add-to-cart-button {
  color: #20124a;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.add-to-cart-button:hover {
  background-color: #20124a;
}

/* Estilo para el mensaje de producto añadido al carrito */
.add-to-cart-message {
  position: fixed;
  top: 50%; /* Centrar verticalmente */
  left: 50%; /* Centrar horizontalmente */
  transform: translate(
    -50%,
    -50%
  ); /* Ajustar posición para centrar exactamente */
  background-color: #20124a;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  z-index: 1000; /* Asegurar que el mensaje esté encima de otros elementos */
  transition: opacity 0.5s;
  opacity: 1;
  animation: fadeInOut 3s ease-in-out;
}

/* Animación para hacer el mensaje más atractivo */
@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}