.category-section {
  background-color: #e2e4f4;
  padding: 20px;
  flex: 1;
  overflow: auto;
}

.category-header {
  text-align: center;
  font-size: 40px;
  margin-bottom: 20px;
  color: rgb(0, 0, 0);
}

.category-content {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category-image {
  width: 350px;
  height: 350px;
  border-radius: 100px;
}

.category-button {
  margin-top: -20px;
  padding: 10px 20px;
  background-color: #f2f2f2;
  font-size: 25px;
  color: #434e9e;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease; 
}

.category-button:hover {
  background-color: #dcdcdc; 
  transform: translateY(-10px); 
}

.subtitle {
  font-size: 25px;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .category-header {
    font-size: 30px;
  }

  .subtitle {
    font-size: 20px;
  }

  .category-content {
    flex-direction: column;
    align-items: center;
  }

  .category-image {
    width: 250px;
    height: 250px;
  }

  .category-button {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .category-header {
    font-size: 25px;
  }

  .subtitle {
    font-size: 18px;
  }

  .category-image {
    width: 200px;
    height: 200px;
  }

  .category-button {
    font-size: 18px;
  }
}