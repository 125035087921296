/* PetFoods.css */

.pet-foods {
  text-align: center;
}

.title {
  color: #f7f7f7;
  font-size: 2.3rem;
  margin-bottom: 20px;
  margin-top: -40px;
  background-color: #20124a;
  padding: 10px 0;
}

.filter-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.filter-button {
  color: white;
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.filter-button:hover {
  background-color: #20124a;
  transform: scale(1.05);
}

.products-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.product-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 250px;
  text-align: center;
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-card:hover {
  transform: scale(1.05);
}

.product-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.product-name {
  margin-top: 15px;
  font-size: 1.2rem;
  color: #333;
}

.product-description {
  margin: 10px 0;
  color: #555;
}

.product-price {
  margin-bottom: 10px;
  color: #28a745;
}


.add-to-cart-button:hover {
  background-color: #20124a;
}

.product-status {
  color: #ff0000;
  font-weight: bold;
  margin-top: 10px;
}

.add-to-cart-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #20124a;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  z-index: 1000;
  transition: opacity 0.5s;
  opacity: 1;
  animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.product-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.preview-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #20124a;
}

.preview-button:hover {
  color: #20124a;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(
    0,
    0,
    0,
    0.7
  ); /* Slightly darker background for better focus */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.modal-content {
  background: #fff;
  padding: 1.5rem;
  border-radius: 20px;
  width: 40%;
  max-width: 800px;
  position: relative;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #000;
}

.product-image {
  width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: contain;
  border-radius: 10px;
  margin-bottom: 20px;
}

.add-to-cart-button {
  background-color: #ffc378;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
  margin-top: 10px;
}

.add-to-cart-button:hover {
  background-color: #20124a;
}
