html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.slider-container {
  width: 100%;
  max-width: 1800px;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.slider {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slider-image-container {
  position: relative;
  width: 100%;
  height: 600px; /* Fijamos la altura del contenedor */
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Asegurar que la imagen mantenga su proporción y ocupe el contenedor */
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  color: black;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.slider-button.left {
  left: 10px;
}

.slider-button.right {
  right: 10px;
}

.new-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  gap: 20px;
  background-color: #f2f2f2;
  margin-top: 10px;
  border-top: 2px solid #ccc; /* Línea divisoria superior */
  border-bottom: 2px solid #ccc; /* Línea divisoria inferior */
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px; /* Reducimos el padding para hacer las tarjetas más pequeñas */
  position: relative;
  transition: transform 0.3s, box-shadow 0.3s;
}

.feature:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 60%;
  width: 1px;
  background-color: #ccc; /* Línea divisoria entre los logos */
}

.feature:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.feature-icon {
  font-size: 35px; /* Reducimos el tamaño del ícono */
  color: #283597;
  margin-bottom: 10px;
  transition: transform 0.3s; /* Efecto de movimiento */
}

.feature-icon:hover {
  transform: rotate(15deg) scale(1.2); /* Efecto de rotación y escalado */
}

.feature-text {
  font-size: 16px; /* Reducimos el tamaño del texto */
  color: #333;
}

.subtitle {
  color: #8f8f8f;
  font-size: 13px; 
  font-weight: normal; 
}

.hover-effect {
  transition: transform 0.3s ease;
}

.hover-effect:hover {
  transform: scale(1.05);
}

/* Estilos responsivos */
@media (max-width: 1024px) {
  .slider-image-container {
    height: 500px; /* Ajustamos la altura del contenedor */
  }

  .feature {
    padding: 15px; /* Reducimos el padding para pantallas medianas */
  }

  .feature-icon {
    font-size: 30px; /* Reducimos el tamaño del ícono para pantallas medianas */
  }

  .feature-text {
    font-size: 14px; /* Reducimos el tamaño del texto para pantallas medianas */
  }

  .subtitle {
    font-size: 12px; /* Reducimos el tamaño del subtítulo para pantallas medianas */
  }
}

@media (max-width: 768px) {
  .slider-image-container {
    height: 400px; /* Ajustamos la altura del contenedor */
  }

  .new-section {
    flex-direction: column; /* Cambiamos la dirección del flex para pantallas pequeñas */
    gap: 10px; /* Reducimos el gap entre los elementos */
  }

  .feature {
    padding: 10px; /* Reducimos el padding para pantallas pequeñas */
  }

  .feature-icon {
    font-size: 25px; /* Reducimos el tamaño del ícono para pantallas pequeñas */
  }

  .feature-text {
    font-size: 12px; /* Reducimos el tamaño del texto para pantallas pequeñas */
  }

  .subtitle {
    font-size: 10px; /* Reducimos el tamaño del subtítulo para pantallas pequeñas */
  }
}

@media (max-width: 480px) {
  .slider-image-container {
    height: 300px; /* Ajustamos la altura del contenedor */
  }

  .slider-button {
    padding: 5px; /* Reducimos el padding de los botones para pantallas muy pequeñas */
  }

  .feature {
    padding: 5px; /* Reducimos el padding para pantallas muy pequeñas */
  }

  .feature-icon {
    font-size: 20px; /* Reducimos el tamaño del ícono para pantallas muy pequeñas */
  }

  .feature-text {
    font-size: 10px; /* Reducimos el tamaño del texto para pantallas muy pequeñas */
  }

  .subtitle {
    font-size: 8px; /* Reducimos el tamaño del subtítulo para pantallas muy pequeñas */
  }
}
