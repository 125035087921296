.footer {
  background-color: #ffffff;
  color: #333;
  padding: 20px 0;
  font-family: Arial, sans-serif;
  position: relative;
}

.footer-divider {
  height: 4px;
  background-color: #20124a;
  margin-bottom: 20px;
}

.footer-content {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-section {
  flex: 1;
  min-width: 200px;
  margin: 10px;
}

.footer-section.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-section.logo .logo-image {
  max-width: 100px;
}

.pay-image {
  max-width: 450px;
}

.footer-section h4 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #302b7b;
}

.footer-section ul li a:hover {
  color: #dbd7e7;
}

.footer-section.contact p {
  margin: 5px 0;
}

.footer-bottom {
  font-size: 19px;
  text-align: center;
  padding: 10px 0;
  background-color: #20124a;
  color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer-bottom p {
  height: 14px;
  margin: 0;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    min-width: 100%;
    text-align: center;
  }

  .footer-section.logo .logo-image {
    max-width: 80px;
  }

  .pay-image {
    max-width: 100%;
  }

  .footer-bottom {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .footer-section h4 {
    font-size: 16px;
  }

  .footer-bottom {
    font-size: 14px;
  }
}
