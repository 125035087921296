.about-us {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
}

.about-us-content {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
}

.about-us-image img {
  width: 100%;
  max-width: 530px;
  border-radius: 10px;
}

.about-us-text {
  max-width: 600px;
  padding: 30px;
  text-align: justify;
}

.about-us-text h1 {
  font-size: 2.2rem;
  margin-bottom: 20px;
}

.about-us-text p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 10px;
}

.about-us-vision-mission {
  padding: 40px 20px;
  width: 100%;
  text-align: justify;
}

.vision-mission-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vision-mission-section {
  width: 100%;
  max-width: 800px;
  margin: 20px 0;
}

.vision-mission-section h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  text-align: center;
}

.vision-mission-section p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 10px;
  text-align: justify;
}

.values-columns {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1080px;
  padding: 10px 0;
}

.value-column {
  flex: 1;
  margin: 10px 0;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5;
}

.value-column strong {
  display: block;
  font-size: 1.1rem;
  margin-bottom: 5px;
}

.divider {
  width: 100%;
  border: 0;
  border-top: 1px solid #2196f3;
  margin: 20px 0;
}

.value-divider {
  display: none;
}

@media (min-width: 768px) {
  .about-us-content {
    flex-direction: row;
  }

  .vision-mission-section h2 {
    font-size: 2rem;
  }

  .values-columns {
    flex-direction: row;
    justify-content: space-between;
    
  }

  .value-column {
    margin: 0 20px;
    text-align: left;
    
  }

  .value-divider {
    display: block;
    
  }
}

@media (min-width: 1024px) {
  .about-us-text h1 {
    font-size: 2.9rem;
  }

  .about-us-text p, .vision-mission-section p {
    font-size: 1.1rem;
  }

  .value-column {
    font-size: 1.1rem;
  }
}
